$(document).ready(function() {
  $(".post__gallery__images").slick({
    dots: true,
    prevArrow:
      '<button type="button" class="btn btn-default previous" aria-label="Previous"><span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span></button>',
    nextArrow:
      '<button type="button" class="btn btn-default next" aria-label="Next"><span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span></button>',
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
  });
});

$(document).on("click", ".post__gallery__nav a", function(event) {
  event.preventDefault();
  if ($(this).hasClass("button-image")) {
    $(".button-image").addClass("active");
    $(".button-video").removeClass("active");
    $(".post__gallery__images").show();
    $(".post__gallery__video").hide();
  } else {
    $(".post__gallery__images").hide();
    $(".post__gallery__video").show();
    $(".button-image").removeClass("active");
    $(".button-video").addClass("active");
  }
});

var $carousel = $(".post__gallery__images");

$(document).on("keydown", function(e) {
  if (e.keyCode == 37) {
    $carousel.slick("slickPrev");
  }
  if (e.keyCode == 39) {
    $carousel.slick("slickNext");
  }
});
