jQuery(document).ready(function($) {

    $('.post__gallery__images').on('init', function(event) {
        event.preventDefault();
        $('.post__gallery').each(function(index, el) {
            var color = $(this).attr('data-color');
            $(this).find('.slick-dots button, .slick-slide').css({
                backgroundColor: color
            });
            $(this).find('.slick-arrow').css('color', color);

        });
    });
    // $('*[data-color]').each(function(index, el) {
    //     var color = $(this).attr('data-color');
    //     $(this).css('color', color);
    // });
    // $('*[data-bg-color]').each(function(index, el) {
    //     var color = $(this).attr('data-bg-color');
    //     $(this).css('background-color', color);
    // });
});
// $(document).on('mouseenter', '.home__featured .post', function(event) {
//     event.preventDefault();
//     var color = $(this).attr('data-bg-color');
//     $(this).find('h4').css('background-color', color);
// }).on('mouseleave', '.home__featured .post', function(event) {
//     event.preventDefault();
//     $(this).find('h4').css('background-color', '');
// });
