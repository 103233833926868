var jqueryPlayer = new Vimeo.Player($('.home__reel iframe'));

$(document).on('click', '.home__image', function(event) {
  event.preventDefault();
  $('.home__image, .home__gif').fadeOut('400', function () {
  	jqueryPlayer.play();
  });
});


