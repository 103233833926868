$(document).on('mouseenter', '.menu.home li', function(event) {
  event.preventDefault();
  $(this).siblings('li').css('opacity', .4);
}).on('mouseleave', '.menu.home li', function(event) {
  event.preventDefault();
  $(this).siblings('li').css('opacity', 1);
});


$(document).on('mouseenter', '.menu:not(.home) li', function(event) {
  event.preventDefault();
  $(this).css('opacity', 1);
  $(this).siblings('li').css('opacity', .4);
}).on('mouseleave', '.menu:not(.home) li', function(event) {
  event.preventDefault();
  $(this).css('opacity', .4);
  $('.menu li.active').css('opacity', 1);
});

function moveLogo() {
  var ww = $(window).width();
  if(ww < 769) {
    $('.menu li.menu__home').prependTo('.menu');
  }
  else {
    $('.menu li.menu__home').insertAfter('.menu li:nth-child(3)');
  }
}

jQuery(document).ready(function($) {
  moveLogo();
});
$(window).resize(function(event) {
  moveLogo();
});

$(document).on('click', '#menu__toggle', function(event) {
  event.preventDefault();
  $(this).toggleClass('open');
  $('#header').toggleClass('open');
});
